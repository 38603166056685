<template>
    <div
        class="rating-subs__wrapper"
        :class="{ 'rating-subs__wrapper--reversed': reversed }"
    >
        <SubscribersCount :amount="subscribers" title="Favorites" />
        <span v-if="showNumberOfRatings" class="rating-subs__ratings-number"
            >({{ reviews }} ratings)</span
        >
        <Rating
            :readonly="true"
            :amount="rating"
            class="rating-component"
            :class="{ 'rating-component--reversed': reversed }"
            :with-text="withText"
            :hard-yellow="hardYellow"
        />
    </div>
</template>

<script>
import SubscribersCount from './../common/SubscribersCount.vue';
import Rating from '@/components/common/RatingWidget.vue';

export default {
    components: {
        SubscribersCount,
        Rating,
    },
    props: {
        rating: { type: Number, default: 0 },
        reviews: { type: Number, default: 0 },
        subscribers: { type: Number, default: 0 },
        readonly: { type: Boolean, default: false },
        reversed: { type: Boolean, default: false },
        showNumberOfRatings: { type: Boolean, default: false },
        withText: { type: Boolean, default: false },
        hardYellow: { type: Boolean, default: false },
    },
};
</script>

<style scoped lang="scss">
.rating-subs__wrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;

    &--reversed {
        flex-direction: row-reverse;
    }

    .rating-subs__ratings-number {
        margin-left: 5px;
        color: #b2b2b2;
        font-family: $palanquin-font;
        font-size: rem(16);
        font-weight: 400;
        letter-spacing: 0.55px;
    }

    .rating-component {
        padding-left: rem(8);

        &--reversed {
            padding: 0;
        }
    }
}
</style>
