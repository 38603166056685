<template>
    <Modal v-if="showModal" size="sm" @close="onNo()">
        <div slot="body" class="modal-content">
            <h2 class="heading heading--lg">{{ question }}</h2>
            <div class="divider divider--10"></div>
            <p
                v-if="subQuestion"
                class="modal__paragraph"
                v-html="subQuestion"
            ></p>

            <div class="divider divider--40"></div>

            <div class="text--center">
                <button
                    class="modal__button-yesno button button--with-icon"
                    @click="onNo()"
                >
                    <span>Cancel</span>
                    <i class="icon-no">
                        <NoIcon />
                    </i>
                </button>
                <button
                    class="modal__button-yesno button button--with-icon active"
                    @click="onYes()"
                >
                    <span>{{ answer }}</span>
                    <i class="icon-finish">
                        <TickIcon />
                    </i>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';

import NoIcon from 'Sprites/no.svg?inline';
import TickIcon from 'Sprites/tick.svg?inline';

export default {
    components: {
        Modal,
        NoIcon,
        TickIcon,
    },
    data() {
        return {
            showModal: false,
            modalData: {},
        };
    },
    computed: {
        question() {
            return this.modalData.question || '';
        },
        subQuestion() {
            return this.modalData.subQuestion || '';
        },
        answer() {
            return this.modalData.answer || 'Yes';
        },
    },
    methods: {
        onYes() {
            this.$emit('dialogClose', true);
        },
        onNo() {
            this.$emit('dialogClose', false);
        },
        init(question, subQuestion, answer) {
            if (!question) {
                throw Error('question is required');
            }
            this.modalData = { question, subQuestion, answer };
            this.showModal = true;
            return new Promise(resolve => {
                this.$once('dialogClose', result => {
                    this.showModal = false;
                    resolve(result);
                });
            });
        },
    },
};
</script>
