<template>
    <div class="rating-wrap">
        <span v-if="readonly" :title="ratingTitle" class="rating-amount">
            <RatingActiveIcon
                :width="svgWidth"
                :height="svgHeight"
                :class="`star star--${svgFillColor}`"
            />
            <span v-if="showNumberOfRatings" class="rating-amount__text">{{
                amount
            }}</span>
            <span v-if="withText" class="rating__button--text">Rate it</span>
        </span>
        <div
            v-else
            :title="ratingTitle"
            class="rating"
            @mouseover="assetHover = true"
            @mouseleave="assetHover = false"
        >
            <StarRating
                :read-only="readonly"
                :increment="1"
                :max-rating="maxStars"
                :show-rating="false"
                :rating="myRating()"
                :star-size="svgWidth"
                :active-color="'#ffa306'"
                :border-color="'#ffa306'"
                :padding="4"
                :star-points="[
                    4.6812,
                    0.0,
                    5.302,
                    0.3399,
                    5.3652,
                    0.444,
                    6.3372,
                    2.604,
                    8.6892,
                    2.868,
                    8.804,
                    2.8916,
                    9.3132,
                    3.372,
                    9.1885,
                    4.0755,
                    9.1092,
                    4.164,
                    7.3572,
                    5.76,
                    7.8372,
                    8.076,
                    7.8495,
                    8.1865,
                    7.6812,
                    8.694,
                    7.1052,
                    8.976,
                    6.8465,
                    8.9152,
                    6.7332,
                    8.868,
                    4.6812,
                    7.704,
                    2.6172,
                    8.868,
                    2.5049,
                    8.9147,
                    1.8132,
                    8.832,
                    1.4969,
                    8.1928,
                    1.5132,
                    8.076,
                    1.9932,
                    5.748,
                    0.2412,
                    4.164,
                    0.2412,
                    4.164,
                    0.0372,
                    3.372,
                    0.5557,
                    2.8824,
                    0.6732,
                    2.856,
                    3.0132,
                    2.604,
                    3.9972,
                    0.444,
                    4.063,
                    0.3399,
                    4.6812,
                    0.0,
                ]"
                @rating-selected="rate"
            />
            <span v-if="withText && !rating" class="rating__button--text"
                >Rate it</span
            >
            <span v-if="withText && rating" class="rating__button--text"
                >Your rating: <strong>{{ rating.rating }}</strong></span
            >
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';

//SVG
import RatingActiveIcon from 'Sprites/rating_active.svg?inline';

export default {
    name: 'Rating',
    components: { RatingActiveIcon, StarRating },
    props: {
        readonly: {
            type: Boolean,
            default: true,
        },
        amount: {
            type: Number,
            default: 0,
        },
        ratingLabel: {
            type: String,
            default: '',
        },
        svgWidth: { type: Number, default: 19 },
        svgHeight: { type: Number, default: 15 },
        svgFill: {
            default: 'yellow',
            validator: function (value) {
                return ['yellow', 'grey'].indexOf(value) !== -1;
            },
        },
        withText: { type: Boolean, default: true },
        showNumberOfRatings: { type: Boolean, default: true },
        hardYellow: { type: Boolean, default: false },
        rating: { type: Object, default: null },
    },
    data() {
        return { assetHover: false, starsHovered: null };
    },
    computed: {
        ratingTitle() {
            if (this.readonly) {
                return 'You need to be logged into an account to rate this asset';
            } else {
                if (this.ratingLabel) {
                    return this.ratingLabel;
                } else {
                    if (this.readonly) {
                        return `Rating: ${this.rating} stars`;
                    } else {
                        return 'Click to rate';
                    }
                }
            }
        },
        maxStars() {
            if (this.assetHover) {
                return 5;
            } else {
                return 1;
            }
        },

        svgFillColor() {
            if (this.hardYellow) {
                return 'yellow';
            } else {
                if (this.readonly) {
                    return 'grey';
                } else {
                    return this.svgFill;
                }
            }
        },
    },
    methods: {
        myRating() {
            if (this.rating === null) {
                return 0;
            } else {
                return this.rating.rating;
            }
        },
        rate(num) {
            if (!this.readonly) {
                this.$emit('rate', num);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.rating-wrap {
    position: relative;
    display: flex;
    align-items: center;

    .rating,
    .rating-amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .rating__button {
            &--text {
                font-family: $palanquin-font;
                text-transform: none;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.45px;
                margin-left: rem(5);
            }
        }
    }

    .star {
        &--yellow {
            fill: #ffa306;
        }
        &--grey {
            fill: $grey-main;
        }
    }
}

.rating-amount {
    display: inline-flex;
    align-items: center;

    &__text {
        font-size: rem(16);
        vertical-align: middle;
        font-weight: 400;
        letter-spacing: 0.4px;
    }
}
</style>
