'use strict';
const format = require('date-format');

const DATE_FORMAT = 'dd.MM.yyyy';
const FULL_DATETIME_FORMAT = 'dd.MM.yyyy hh:mm';

/**
 * @covered tests/unit/dateTimeUtil.js
 */
export default class DateTimeUtil {
    static getHumanReadableDiffString(date) {
        let now = new Date();
        let delta = Math.round((now.getTime() - date.getTime()) / 1000);

        let minute = 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7;

        let fuzzy;

        if (delta < 30) {
            fuzzy = 'just now';
        } else if (delta < minute) {
            fuzzy = delta + ' seconds ago';
        } else if (delta < 2 * minute) {
            fuzzy = 'a minute ago';
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minutes ago';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 hour ago';
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' hours ago';
        } else if (delta < day * 2) {
            fuzzy = 'yesterday';
        } else if (delta < day * 7) {
            fuzzy = Math.floor(delta / day) + ' days ago';
        } else if (delta < week * 2) {
            fuzzy = 'a week ago';
        } else if (delta <= week * 4) {
            fuzzy = Math.floor(delta / week) + ' weeks ago';
        } else {
            fuzzy = format(DATE_FORMAT, date);
        }
        return fuzzy;
    }

    static getFullDateTime(date) {
        return format(FULL_DATETIME_FORMAT, date);
    }
}
