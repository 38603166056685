<template>
    <div class="favorite-widget">
        <button
            :title="favoriteTitle"
            class="button favorite-widget__button"
            @mouseover="assetHover = true"
            @mouseleave="assetHover = false"
            @click="toggleFavorite()"
        >
            <transition name="fade" mode="out-in">
                <HeartFullIcon
                    v-if="
                        (subscribed && !assetHover) ||
                        (!subscribed && assetHover)
                    "
                    key="favorite"
                    :width="svgWidth"
                    :height="svgHeight"
                    :class="`favorite-widget__button--icon--${svgFillColor}`"
                />
                <HeartIdleIcon
                    v-if="
                        (!subscribed && !assetHover) ||
                        (subscribed && assetHover)
                    "
                    key="unfavorite"
                    :width="svgWidth"
                    :height="svgHeight"
                    :class="`favorite-widget__button--icon--${svgFillColor}`"
                />
            </transition>
            <span
                v-if="withText && !subscribed"
                class="favorite-widget__button--text"
                >Favorite it</span
            >
            <span
                v-if="withText && subscribed"
                class="favorite-widget__button--text"
                >Favorite</span
            >
        </button>
    </div>
</template>

<script>
//SVG
import HeartFullIcon from 'Sprites/heart.svg?inline';
import HeartIdleIcon from 'Sprites/heart_idle.svg?inline';

export default {
    components: { HeartFullIcon, HeartIdleIcon },
    props: {
        svgWidth: { type: Number, default: 19 },
        svgHeight: { type: Number, default: 15 },
        svgFill: {
            default: 'white',
            validator: function (value) {
                return ['white', 'pink', 'grey'].indexOf(value) !== -1;
            },
        },
        withText: { type: Boolean, default: false },
        subscribed: { type: Boolean, default: false },
        inactive: { type: Boolean, default: false },
    },
    data() {
        return { assetHover: false, favorited: null };
    },
    computed: {
        favoriteTitle() {
            if (this.inactive) {
                return 'You need to be logged into an account to favorite this asset';
            } else {
                if (this.subscribed == true) {
                    return 'Remove from favorites';
                } else {
                    return 'Add to favorites';
                }
            }
        },
        svgFillColor() {
            if (this.inactive) {
                return 'grey';
            } else {
                return this.svgFill;
            }
        },
    },
    methods: {
        toggleFavorite() {
            if (!this.inactive) {
                if (this.subscribed == false) {
                    this.favorited = true;
                } else {
                    this.favorited = false;
                }
                this.$emit('toggle-favorite', this.favorited);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.favorite-widget {
    .favorite-widget__button {
        outline: none;
        color: $blue-main;
        display: flex;
        align-items: center;

        &--icon {
            &--pink {
                fill: #ec37a6;
            }

            &--white {
                fill: #fff;
            }
            &--grey {
                fill: $grey-main;
            }
        }

        &--text {
            font-family: $palanquin-font;
            text-transform: none;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.45px;
            margin-left: rem(5);
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity $transition-values;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
