var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rating-wrap"},[(_vm.readonly)?_c('span',{staticClass:"rating-amount",attrs:{"title":_vm.ratingTitle}},[_c('RatingActiveIcon',{class:`star star--${_vm.svgFillColor}`,attrs:{"width":_vm.svgWidth,"height":_vm.svgHeight}}),(_vm.showNumberOfRatings)?_c('span',{staticClass:"rating-amount__text"},[_vm._v(_vm._s(_vm.amount))]):_vm._e(),(_vm.withText)?_c('span',{staticClass:"rating__button--text"},[_vm._v("Rate it")]):_vm._e()],1):_c('div',{staticClass:"rating",attrs:{"title":_vm.ratingTitle},on:{"mouseover":function($event){_vm.assetHover = true},"mouseleave":function($event){_vm.assetHover = false}}},[_c('StarRating',{attrs:{"read-only":_vm.readonly,"increment":1,"max-rating":_vm.maxStars,"show-rating":false,"rating":_vm.myRating(),"star-size":_vm.svgWidth,"active-color":'#ffa306',"border-color":'#ffa306',"padding":4,"star-points":[
                4.6812,
                0.0,
                5.302,
                0.3399,
                5.3652,
                0.444,
                6.3372,
                2.604,
                8.6892,
                2.868,
                8.804,
                2.8916,
                9.3132,
                3.372,
                9.1885,
                4.0755,
                9.1092,
                4.164,
                7.3572,
                5.76,
                7.8372,
                8.076,
                7.8495,
                8.1865,
                7.6812,
                8.694,
                7.1052,
                8.976,
                6.8465,
                8.9152,
                6.7332,
                8.868,
                4.6812,
                7.704,
                2.6172,
                8.868,
                2.5049,
                8.9147,
                1.8132,
                8.832,
                1.4969,
                8.1928,
                1.5132,
                8.076,
                1.9932,
                5.748,
                0.2412,
                4.164,
                0.2412,
                4.164,
                0.0372,
                3.372,
                0.5557,
                2.8824,
                0.6732,
                2.856,
                3.0132,
                2.604,
                3.9972,
                0.444,
                4.063,
                0.3399,
                4.6812,
                0.0,
            ]},on:{"rating-selected":_vm.rate}}),(_vm.withText && !_vm.rating)?_c('span',{staticClass:"rating__button--text"},[_vm._v("Rate it")]):_vm._e(),(_vm.withText && _vm.rating)?_c('span',{staticClass:"rating__button--text"},[_vm._v("Your rating: "),_c('strong',[_vm._v(_vm._s(_vm.rating.rating))])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }