var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"favorite-widget"},[_c('button',{staticClass:"button favorite-widget__button",attrs:{"title":_vm.favoriteTitle},on:{"mouseover":function($event){_vm.assetHover = true},"mouseleave":function($event){_vm.assetHover = false},"click":function($event){return _vm.toggleFavorite()}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
                    (_vm.subscribed && !_vm.assetHover) ||
                    (!_vm.subscribed && _vm.assetHover)
                )?_c('HeartFullIcon',{key:"favorite",class:`favorite-widget__button--icon--${_vm.svgFillColor}`,attrs:{"width":_vm.svgWidth,"height":_vm.svgHeight}}):_vm._e(),(
                    (!_vm.subscribed && !_vm.assetHover) ||
                    (_vm.subscribed && _vm.assetHover)
                )?_c('HeartIdleIcon',{key:"unfavorite",class:`favorite-widget__button--icon--${_vm.svgFillColor}`,attrs:{"width":_vm.svgWidth,"height":_vm.svgHeight}}):_vm._e()],1),(_vm.withText && !_vm.subscribed)?_c('span',{staticClass:"favorite-widget__button--text"},[_vm._v("Favorite it")]):_vm._e(),(_vm.withText && _vm.subscribed)?_c('span',{staticClass:"favorite-widget__button--text"},[_vm._v("Favorite")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }