<template>
    <span v-if="amount > 0" :title="title" class="subscribers-count">
        <UsersIcon class="rating-amount__icon" />
        <span class="rating-amount__text">{{ amount }}</span>
    </span>
</template>

<script>
import UsersIcon from 'Sprites/players.svg?inline';

export default {
    name: 'SubscribersCount',
    components: { UsersIcon },
    props: {
        title: { type: String, default: 'Favorites' },
        amount: { type: Number, default: 0 },
    },
};
</script>

<style lang="scss" scoped>
.subscribers-count {
    display: inline-flex;
    align-items: center;
    margin-left: rem(10);
    line-height: 1;

    .rating-amount {
        &__icon {
            color: #868686;
            height: 14px;
        }
        &__text {
            margin-left: rem(3);
            font-family: $palanquin-font;
            font-size: rem(16);
            font-weight: 400;
            letter-spacing: 0.4px;
            vertical-align: middle;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
</style>
